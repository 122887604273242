import { motion } from "framer-motion";
import { ArrowLeft, ArrowRight } from "lucide-react";
import React, { memo } from "react";
import { cn } from "ui/lib/utils";
import { useChatBotActions } from "./ChatBotActionsProvider";
import { Example, ExampleSection, ExamplesLayout } from "./ChatBotUIProvider";

export const ExampleList = memo(
  ({
    examples,
    examplesLabel,
    exampleSections,
    examplesLayout,
    setExamples,
    setExamplesLabel,
    scrollToBottom,
    messagesLength,
    alwaysShowExamples,
  }: {
    examples: Example[];
    examplesLabel?: string | React.ReactNode;
    exampleSections?: ExampleSection[];
    messagesLength: number;
    examplesLayout: ExamplesLayout;
    alwaysShowExamples: boolean;
    setExamples: any;
    setExamplesLabel: any;
    scrollToBottom: any;
  }) => {
    const { handleSubmitExample } = useChatBotActions();
    const findIndexByLabel = (label, sections) =>
      sections.findIndex((section) => section.section === label);

    const getNextIndex = (index, length) => (index + 1) % length;
    const getPrevIndex = (index, length) => (index - 1 + length) % length;

    return (alwaysShowExamples || [0, 1].includes(messagesLength)) &&
      (examples?.length || exampleSections?.length) ? (
      <motion.div
        initial={messagesLength === 1 && { opacity: 0 }}
        animate={messagesLength === 1 && { opacity: 1 }}
        transition={{ delay: 1.5 }}
        style={{ marginTop: "auto" }}
        className={cn("mt-auto", {
          "pt-12": [0, 1].includes(messagesLength),
          "pt-6": ![0, 1].includes(messagesLength),
        })}
      >
        <div className="flex space-x-2">
          {exampleSections?.length ? (
            <button
              className="p-1 rounded-full"
              onClick={() => {
                const index = findIndexByLabel(examplesLabel, exampleSections);
                const prevIndex = getPrevIndex(index, exampleSections.length);
                setExamplesLabel(exampleSections[prevIndex].section);
                setExamples(exampleSections[prevIndex].prompts);
                scrollToBottom();
              }}
            >
              <ArrowLeft className="w-3 h-3 text-foreground" />
            </button>
          ) : null}
          <motion.h2 className="ml-auto text-sm text-muted-foreground leading-snug">
            {examplesLabel || "Suggestions"}
          </motion.h2>
          {exampleSections?.length ? (
            <button
              className="p-1 rounded-full"
              onClick={() => {
                const index = findIndexByLabel(examplesLabel, exampleSections);
                const nextIndex = getNextIndex(index, exampleSections.length);
                setExamplesLabel(exampleSections[nextIndex].section);
                setExamples(exampleSections[nextIndex].prompts);
                scrollToBottom();
              }}
            >
              <ArrowRight className="w-3 h-3 text-foreground" />
            </button>
          ) : null}
        </div>
        <div
          className={cn("py-1 w-full", {
            "flex flex-col justify-end gap-1 text-left":
              examplesLayout === "columns",
            "grid grid-cols-2 gap-2 items-start text-right":
              examplesLayout === "grid",
          })}
        >
          {examples.map((example, index) => (
            <motion.div
              key={index}
              className={cn("", {
                "w-auto ml-auto": examplesLayout === "columns",
                "w-full": examplesLayout === "grid",
              })}
            >
              <button
                onClick={async () => {
                  handleSubmitExample(example, index);
                }}
                className={cn(
                  "relative col-1 w-full h-full rounded-xl bg-card hover:bg-secondary transition-colors border text-neutral-300 group-hover:text-foreground text-sm p-2 transition-colors duration-200 ease-in-out"
                )}
              >
                <span>{example?.abbreviatedUser || example?.user}</span>
              </button>
            </motion.div>
          ))}
        </div>
      </motion.div>
    ) : null;
  }
);
